#main-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 72px;
  padding: 0 36px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background-color: #141414;
  z-index: 9999;
}

#main-navigation .body {
  display: flex;
}

#logo {
  display: none;
}
#main-navigation ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
}

#main-navigation ul li > * {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}
#main-navigation ul li a {
  color: #fff;
  text-decoration: none;
}

#main-navigation .main-navigation-icon {
  fill: #fff;
  width: 1.5rem;
  height: 1.5rem;
}

ul.meals {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

ul.meals li,
ul.meals li .time {
  display: flex;
  align-items: center;
}

ul.meals li {
  height: 4rem;
  gap: 0.75rem;
}

ul.meals li:hover {
  background-color: #eee;
}

ul.meals li .time {
  background-color: #31394f;
  border-radius: 0.25rem;
  height: 100%;
  width: 4rem;
  color: #fff;
  justify-content: center;
  font-size: 0.9rem;
}
ul.meals li .title {
  font-weight: bold;
}

ul.meals li .values {
  display: flex;
  font-size: 0.9rem;
  gap: 1rem;
}

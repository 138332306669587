@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

html,
body {
  margin: 0;
  font-size: 16px;
  background-color: #f5f6f6;
  /* TODO figure out good line-height */
}

* {
  color: #31394f;
  font-family: "Open Sans", sans-serif;
}

h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3,
h4 {
  font-size: 1.25rem;
}
h1,
h2,
h3,
h4 {
  margin: 1rem 0;
}

.input-row {
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 8px 0;
  flex-wrap: wrap;
  width: 100%;
}
input[type="checkbox"] {
  width: 24px;
  height: 24px;
  margin: 0;
}

.timeslots .timeslots-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
}

.timeslots .timeslots-column.author {
  flex-grow: 0;
}

.calendar {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.calendar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.calendar ul.week-row {
  display: flex;
  gap: 4px;
}
.calendar ul.week-row li {
  display: flex;
  border-radius: 6px;
  border: 1px solid #a8afb0;
  height: 48px;
  width: 48px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.calendar ul.week-row li:hover {
  background-color: #31394f;
  color: #fff;
  border-color: #31394f;
}

.flexRight {
  justify-content: flex-end;
}

#logo {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #263238;
  display: flex;
  flex-direction: column;
}
a#logo {
  text-decoration: none;
}
a#logo:hover,
a#logo:hover > * {
  color: #000;
}

#logo span {
  font-size: 0.75rem;
  font-weight: normal;
}

.datepicker {
  border: 1px solid #c0c3c8;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  border-radius: 1rem;
  padding: 0.5rem;
}

.datepicker > * {
  flex: 1;
  display: flex;
  justify-content: center;
}

.datepicker .active {
  background-color: #000;
  color: #fff;
  padding: 0.75rem;
}

ul.key-value-table {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 0;
}
ul.key-value-table li {
  display: flex;
  border-bottom: 1px solid #ddd;
}
ul.key-value-table li span {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  font-weight: normal;
}
ul.key-value-table li span:first-child {
  justify-content: flex-start;
  font-weight: bold;
}

.border-component {
  padding: 1rem 1.25rem;
  border: 1px solid #ecf0f5;
  border-radius: 0.4rem;
  margin-top: 1rem;
}

a.link {
  color: blue;
  text-decoration: underline;
}

.slider-mark-current {
  padding: 0 0.25rem;
  text-align: center;
  background-color: #3182ce;
  color: #fff;
  margin-top: -2.5rem;
}

.caloric-balance-slider {
  margin-top: 2rem;
}

/*
* Day summary
*/
.day-summary-card {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  overflow: hidden;
  flex-wrap: wrap;
}
.day-summary-card .date {
  width: 100%;
}

/*
*
*/
/**
*  Circular progress 
*/
.circular-progress {
  height: 9rem;
  width: 9rem;
  position: relative;
}
.circular-progress,
.circular-progress .inner-circle,
.circular-progress .circle > *,
.circular-progress .progress {
  border-radius: 100%;
}

.circular-progress.pie .inner-circle {
  background: none; /* Pie chart progress */
}
.circular-progress .inner-circle {
  position: absolute;
  z-index: 100;
  top: 0.5rem;
  left: 0.5rem;
  height: 8rem;
  width: 8rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
  font-weight: bold;
}
.circular-progress .circle > * {
  background-color: #ccf1e7;
}
.circular-progress .circle .left-circle {
  transform: rotate(180deg);
}
.circular-progress .progress,
.circular-progress .circle > * {
  clip: rect(0, 9rem, 9rem, 4.5rem);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.circular-progress .progress {
  background: #00ba88;
}

/**
* Topbar in mobile, breadcrumbs on desktop
*/
#topbar {
  /* Make sure to take up space where the element appears */
  height: 4rem;
  z-index: 9999;
}
#topbar .body {
  /* Stick the topbar to the top of the screen. */
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

#topbar .body .back {
  position: absolute;
  left: 1.5rem;
  display: flex;
  align-items: center;
  font-size: 0;
}
#topbar .body .title {
  text-transform: capitalize;
}
#topbar .body .back svg.icon {
  height: 1.5rem;
  width: 1.5rem;
}

@media screen and (min-width: 800px) {
  /* In desktop we turn this into a big button with a border and show the button text */
  #topbar .body .back {
    border: 1px solid #000;
    border-radius: 0.25rem;
    padding: 0.25rem 0.75rem;
    font-size: inherit;
    position: relative;
    left: 0;
    cursor: pointer;
    text-decoration: none;
  }
  #topbar .body .back:hover {
    background-color: #31394f;
    color: #fff;
    border-color: #31394f;
  }
  #topbar .body .back:hover .icon {
    fill: #fff;
  }
  #topbar {
    margin-bottom: 1.5rem;
  }
  #topbar,
  #topbar .body {
    height: auto;
    position: relative;
    justify-content: flex-start;
    box-shadow: none;
  }
  #topbar .title {
    display: none;
  }
}

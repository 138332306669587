#root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#main-wrapper {
  width: 720px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

ul.menu-group {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  list-style-type: none;
  margin: 0;
  padding: 1rem;
  background-color: #f2f4f6;
  border-radius: 0.5rem;
  flex-direction: column;
  gap: 0.5rem;
}
ul.menu-group li > * {
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;
}

ul.menu-group li {
  border: 1px solid #b8b9bb;
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
}
ul.menu-group li a {
  text-decoration: none;
}
ul.menu-group li:hover {
  background-color: #31394f;
  border-color: #31394f;
}
ul.menu-group li:hover .icon {
  fill: #fff;
}
ul.menu-group li:hover a {
  color: #fff;
}

ul.menu-group li .icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media screen and (max-width: 520px) {
}

/* WIDTH 0-600 */
@media screen and (max-width: 600px) {
}

/* WIDTH 0-800 */
@media screen and (max-width: 800px) {
  #main-wrapper {
    width: 100%;
    position: relative;
    padding-bottom: 200px;
    gap: 0;
  }

  #main-navigation.hide-on-mobile {
    display: none;
  }
  /* On mobile we'll treet the box as a simple wrapper. Strip the styling. Box sections will be turned into boxes instead */
  #main-wrapper .box {
    padding: 0;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    background: 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  #main-wrapper .box .box-section {
    border-radius: 0.25rem;
    padding: 1rem;
    margin-top: 0;
    margin: 0.75rem;
    margin-bottom: 0;
    background-color: #fff;
  }

  #main-navigation ul,
  #main-navigation .body {
    flex-basis: 100%;
  }

  #main-navigation ul li.fab {
    position: absolute;
    top: -5.5rem;
    right: 1rem;
  }
  #main-navigation ul li.fab > * {
    gap: 0;
  }
  #main-navigation ul li.fab a {
    display: flex;
    justify-content: center;
    height: 4.25rem;
    width: 4.25rem;
    background: #1bb55c;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
    border-radius: 100%;
    font-size: 0;
  }
  #main-navigation ul li.fab a:active {
    transform: translateY(0.1rem);
  }
  /* Hide FAB. Should only happen on mobile devices, where FAB exists */
  #main-navigation ul li.fab.hide-fab {
    display: none;
  }
}

/* WIDTH 600-800 */
@media screen and (min-width: 600px) and (max-width: 800px) {
}

/* WIDTH 600 >  */
@media screen and (min-width: 600px) {
}

/* WIDTH 800 > 
pretty much desktop view
*/
@media screen and (min-width: 800px) {
  #main-wrapper {
    width: 800px;
    /* Height of main-navigation (absolute position) + padding  ; */
    padding-top: calc(6rem + 2rem);
  }

  /* Move the navigation bar to the top. Now acts as a header */
  #main-navigation {
    top: 0;
    /* TODO better background color */
    background-color: #fff;
    height: 6rem;
  }

  /* Get space between the components of the "header" */
  #main-navigation .body {
    width: 800px;
    justify-content: space-between;
  }

  /* Navigation items are bound to one row and have more spacing */
  #main-navigation ul {
    gap: 0.5rem;
  }
  #main-navigation ul li > * {
    flex-direction: row;
  }

  #main-navigation ul li {
    border: 1px solid #263238;
  }
  #main-navigation ul li a {
    padding: 0.75rem 1rem;
    color: #263238;
  }
  #main-navigation ul li:hover,
  #main-navigation ul li:hover a {
    background-color: #263238;
    color: #fff;
  }
  #main-navigation ul li a .main-navigation-icon {
    display: none;
  }

  /* Now that there's more space on the screen, we can display the logo without cluttering the UX */
  #logo {
    display: flex;
  }
}

/* WIDTH 1200 > */
@media screen and (min-width: 1200px) {
}

/* WIDTH 1400 > */
@media screen and (min-width: 1400px) {
}
/* TODO allow more columns horizontally? */

/* 
Commonly used UI elements
Grouped in one file to make it easier to reuse the styling in other projects
*/

/* 
* Progressbar 
*/
.progressbar {
  display: block;
  background-color: #ccf1e7;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  margin: 16px 0;
}

.progressbar .progress-fill {
  height: 100%;
  background: #00ba88;
}

/*
* Box
*/
.box {
  padding: 2rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.box > .title {
  font-size: 1.75rem;
  font-weight: bold;
  padding-bottom: 6px;
  margin-bottom: 16px;
}

.box .box-section {
  margin-bottom: 2rem;
}

.box .box-section > p {
  margin-top: 0;
}

.box .box-section .title {
  font-weight: bold;
  font-size: 1.25rem;
}

/*
* Common properties
*/
.full-flex {
  flex: 1;
}

/* TODO look into creating a ul override class */
nav.bento-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
}

nav.bento-menu ul li {
  display: flex;
  flex: 1 0 24%;
  align-items: center;
  justify-content: center;
  height: 128px;
  border: 1px solid #979797;
  border-radius: 6px;
}

nav.bento-menu ul li > * {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}

nav.bento-menu ul li .icon {
  height: 24px;
  width: 24px;
}

nav.bento-menu ul li a {
  text-decoration: none;
}

/* 
* BUTTON
*/
button.button,
a.button {
  border: 1px solid #a8afb0;
  padding: 0 2.5rem;
  height: 3.5rem;

  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  color: #31394f;
  text-decoration: none;
  background-color: #fff;
  border-radius: 0.25rem;
  cursor: pointer;
}

.button:hover,
a.button:hover,
.button.red:hover,
.button:hover .icon,
.button.red:hover .icon,
a.button:hover .icon {
  background-color: #31394f;
  color: #fff;
  border-color: #31394f;
  fill: #fff;
}
.button .icon,
a.button .icon {
  width: 24px;
  height: 24px;
}
.button:active {
  transform: translateY(1px);
}

.button.red {
  background-color: #f25d5d;
  color: #fff;
  border-color: #f25d5d;
}

.hide {
  display: none;
}

/**
* Clears the formating of the UL element so it can be used for menus and such
*/
.clear-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.textWhite {
  color: #fff;
}
.textDark {
  color: #393f4f;
}
.bgRed {
  background-color: #f25d5d;
}
.bgBlue {
  background-color: #0880ae;
}
.bgOrange {
  background-color: #f38637;
}
.bgBlack {
  background-color: #000;
}
.bgGreen {
  background-color: #4d9c50;
}

.button.submit {
  background-color: #4d9c50;
  color: #fff;
  border-color: #4d9c50;
}

.full-width {
  width: 100%;
}

.space-bottom {
  margin-bottom: 1rem;
}

a.no-style-link {
  text-decoration: none;
}

/*
* ALERT
*/
.alert {
  display: block;
  padding: 0.75rem 1rem;
  color: #000;
  border: 1px solid #fbbc04;
  border-radius: 4px;
  background: #fff9e0;
}

.alert.error {
  background-color: #fac0bb;
  border-color: #ea4335;
}
.alert.notice {
  background-color: #d5eeff;
  border-color: #498ab6;
}
